import React from 'react';
import princeImage from '../../image/prince.png';
import {AiOutlineDownload} from "react-icons/ai";

const Home = () => {
    return (
        <main name="Home" className='flex flex-col lg:mx-40 md:flex-row gap-2 items-center md:mx-8 mt-8  min-h-[82.5vh]  '>
            
            <section className='w-full md:w-1/2 min-h-80 px-8'>
                <div className="w-full md:w-1/2 font-sans font-extrabold flex flex-col gap-4">
                <code>🙋 Hi there,</code>
                <h2 className="text-white text-6xl">I am </h2>
                <h1 className="w-autotext-transparent text-8xl ">Prince
                </h1>
                <p className="text-white font-normal w-80 min-w-fit: text-start">
                I am a <b>full-stack web developer</b>. specializing in creating accessible and user-oriented web products. I transform ideas into real web applications, ensuring they offer a great user experience .
                </p>
                <button className="px-5 py-3  w-fit bg-[#feb273] rounded text-white flex items-center gap-2 hover:scale-110 transition duration-500 ease-in-out" type="button"><a href="/document/Prince'sCV.pdf" className="flex gap-2" download="prince_s_cv.pdf">Resume <AiOutlineDownload className='mt-1' /></a></button></div>
            </section>
            <section class="w-full md:w-1/2 min-h-80 flex justify-center" >
            <div className='lg:w-[50%] md:w-[50%] pb-12 rounded-lg  sm:mx-4' >

            <span className='block px-8 lg:px-auto' >
                <img className='rounded-lg  scale-x-100 hover:rotate-1 lg:ml-[1.75rem] ' src={princeImage} alt="" />
            </span>
            </div>
            </section>
        </main>
    );
}

export default Home;
